import {getCompatibleImageFileExtension} from '@Utils/image.util';
import {MediaItemSizeType, repoURL} from '@Libraries/s3-library';
import {getDirPathForUserImage, getUserImageScreenFilename, UserImageSource} from '@Libraries/user-image-library';
import {getDirPathForPMWStockMedia, getPMWStockImageFilename} from '@Libraries/pmw-stock-media-library';
import {PMWStockImageSource} from '@Libraries/pmw-stock-media-library.types';
import {getWriteBucket} from '@Utils/s3.util';
import {isUserPremium} from '@Libraries/user.library';

export const ImageItemSource = {
  ...UserImageSource,
  ...PMWStockImageSource,
};

export type ImageItemSourceType = (typeof ImageItemSource)[keyof typeof ImageItemSource];

const TIER_SCREEN_IMAGE_EXTENSION = 'webp';
export const REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION = '_removedbg';
export const REMOVED_BG_IMAGE_EXTENSION = 'webp';

export enum ScreenTier {
  SMALL = 'SMALL',
  BIG = 'BIG',
}

export interface ScreenTeirDetail {
  s3NameSuffix: string;
  minTargetDimension: number;
  maxDimension: number;
}

type ScreenTeirDetails = Record<ScreenTier, ScreenTeirDetail>;

export const SCREEN_TIER_DETAILS: ScreenTeirDetails = {
  [ScreenTier.SMALL]: {
    s3NameSuffix: '_small',
    minTargetDimension: 0,
    maxDimension: 800,
  },
  [ScreenTier.BIG]: {
    s3NameSuffix: '_big',
    minTargetDimension: 801,
    maxDimension: 1600,
  },
};

export const getImageScreenURL = (hashedFilename: string, extension: string, source: ImageItemSourceType): string => {
  let fileName;
  if (source === PMWStockImageSource.PMW_EXTRACTED_GETTY_STICKER) {
    fileName = getGettyImageScreenObjectName(hashedFilename);
  } else if (isSourcePMWStockImage(source)) {
    fileName = getPMWStockImageFilename(hashedFilename);
  } else if (isSourceGetty(source)) {
    fileName = getGettyImageScreenFilename(hashedFilename, extension);
  } else {
    fileName = getUserImageScreenFilename(hashedFilename, extension);
  }

  return repoURL(`${getDirPathForImageItem(source, MediaItemSizeType.SCREEN)}/${fileName}`, getWriteBucket());
};

export const getUrlForImageItemBigTierScreenSize = (hashedFilename: string, source: ImageItemSourceType): string => {
  return getUrlForImageItemScreenTier(hashedFilename, source, ScreenTier.BIG);
};

export const getDirPathForImageItem = (source: ImageItemSourceType, sizeType: MediaItemSizeType): string => {
  if (isSourcePMWStockImage(source)) {
    return getDirPathForPMWStockMedia(source, sizeType);
  }

  return getDirPathForUserImage(sizeType);
};

export const getUrlForImageItemScreenTier = (hashedFilename: string, source: ImageItemSourceType, tier: ScreenTier, isBackgroundRemoved = false): string => {
  const objectName = getS3ObjectNameForImage(hashedFilename, source, tier, isBackgroundRemoved);

  const fileName = `${objectName}.${getCompatibleImageFileExtension(TIER_SCREEN_IMAGE_EXTENSION)}`;
  return repoURL(`${getDirPathForImageItem(source, MediaItemSizeType.SCREEN)}/${fileName}`, getWriteBucket());
};

export const getImageItemBigScreenSizeRemovedBackgroundUrl = (hashedFilename: string, source: ImageItemSourceType): string => {
  return repoURL(
    `${getDirPathForImageItem(source, MediaItemSizeType.SCREEN)}/${hashedFilename}${REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION}${
      SCREEN_TIER_DETAILS[ScreenTier.BIG].s3NameSuffix
    }.${REMOVED_BG_IMAGE_EXTENSION}`,
    getWriteBucket()
  );
};

export const getRemoveBGPostFix = (): string => {
  return `${REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION}.${getCompatibleImageFileExtension(REMOVED_BG_IMAGE_EXTENSION)}`;
};

const isSourcePMWStockImage = (source: ImageItemSourceType): boolean => {
  return Object.values(PMWStockImageSource).includes(source);
};

export const isSourceGetty = (source: ImageItemSourceType): boolean => {
  return source === UserImageSource.GETTY;
};

export const getGettyImageScreenObjectName = (hashedFilename: string): string => {
  if (isUserPremium()) {
    return `${hashedFilename}_wf`;
  }

  return `${hashedFilename}`;
};

export const getGettyImageScreenFilename = (hashedFilename: string, extension: string): string => {
  return `${getGettyImageScreenObjectName(hashedFilename)}.${extension}`;
};

export const getS3ObjectNameForImage = (hashedFilename: string, source: ImageItemSourceType, tier: ScreenTier, isBackgroundRemoved = false): string => {
  let objectName = hashedFilename;

  if ((isSourceGetty(source) && !isBackgroundRemoved) || source === PMWStockImageSource.PMW_EXTRACTED_GETTY_STICKER) {
    objectName = getGettyImageScreenObjectName(hashedFilename);
  }

  const screenSizeSuffix = SCREEN_TIER_DETAILS[tier].s3NameSuffix;

  return `${objectName}${isBackgroundRemoved ? REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION : ''}${screenSizeSuffix}`;
};
