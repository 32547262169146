import type {RGB} from '@Utils/color.util';
import type {QRCodeItem, QRCodeItemObject} from '@PosterWhiteboard/items/qr-code-item.class';
import type {VideoItem, VideoItemObject} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {FancyTextItem, FancyTextItemObject} from '@PosterWhiteboard/items/fancy-text-item/fancy-text-item.class';
import type {ImageItem, ImageItemObject} from '@PosterWhiteboard/items/image-item/image-item.class';
import type {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import type {VectorItem} from '@PosterWhiteboard/items/vector-item/vector-item.class';
import type {TextSlideItem, TextSlideItemObject} from '@PosterWhiteboard/items/slideshow-item/slide-items/text-slide-item.class';
import type {ImageSlideItem, ImageSlideItemObject} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import type {VideoSlideItem, VideoSlideItemObject} from '@PosterWhiteboard/items/slideshow-item/slide-items/video-slide-item.class';
import type {VectorItemObject} from '@PosterWhiteboard/items/vector-item/vector-item.types';
import type {TextItemObject} from '@PosterWhiteboard/items/text-item/text-item.types';
import type {Item} from '@PosterWhiteboard/items/item/item.class';
import type {StickerItem, StickerItemObject} from '@PosterWhiteboard/items/sticker-item.class';
import type {SlideshowItem, SlideshowItemObject} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import type {ItemBorderObject} from '@PosterWhiteboard/classes/item-border.class';
import type {TableItem, TableItemObject} from '@PosterWhiteboard/items/table-item/table-item.class';
import type {MenuItem, MenuItemObject} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import type {TabsItem, TabsItemObject} from '@PosterWhiteboard/items/tabs-item/tabs-item.class';
import type {ImageBackgroundItem} from '@PosterWhiteboard/page/background/image-background-item.class';
import type {ItemAuraObject} from '@PosterWhiteboard/classes/item-aura.class';
import type * as Fabric from '@postermywall/fabricjs-2';
import type {TranscriptItem} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import type {TranscriptItemObject} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';

export type ItemType =
  | StickerItem
  | ImageItem
  | QRCodeItem
  | VideoItem
  | FancyTextItem
  | TextItem
  | VectorItem
  | SlideshowItem
  | TextSlideItem
  | ImageSlideItem
  | VideoSlideItem
  | TableItem
  | MenuItem
  | TabsItem
  | ImageBackgroundItem
  | TranscriptItem;

export type ItemObject =
  | ImageItemObject
  | StickerItemObject
  | VideoItemObject
  | QRCodeItemObject
  | VectorItemObject
  | TextItemObject
  | FancyTextItemObject
  | TableItemObject
  | MenuItemObject
  | TabsItemObject
  | SlideshowItemObject
  | TranscriptItemObject;

export type ItemsWithTextStyles = TextSlideItem | TextItem | TabsItem | TableItem;

export type SlideItemObject = TextSlideItemObject | ImageSlideItemObject | VideoSlideItemObject;

export type ItemsWithFonts = TextItem | TabsItem | TableItem | MenuItem | SlideshowItem;

export const RESELECT_ITEM_EVENT_TYPE = 'reselectItem';

export enum ITEM_TYPE {
  IMAGE = 'image',
  FANCY_TEXT = 'fancytext',
  QR_CODE = 'qrcode',
  VIDEO = 'video',
  STICKER = 'sticker',
  SLIDESHOW = 'slideshow',
  TEXT = 'text',
  VECTOR = 'vector',
  TEXTSLIDE = 'textslide',
  IMAGESLIDE = 'imageslide',
  VIDEOSLIDE = 'videoslide',
  DRAWING = 'drawing',
  TAB = 'tabs',
  TABLE = 'table',
  MENU = 'menu',
  IMAGEBACKGROUND = 'imagebackground',
  TRANSCRIPT = 'transcript',
}

export interface FabricLoadingObjects {
  group: Fabric.Group;
  text: Fabric.Text;
  background: Fabric.Rect;
  circularLoaderProgress: Fabric.Circle;
  circularLoaderBackground: Fabric.Circle;
}

export interface ShowItemLoadingOtps {
  /**
   * Array of strings to show for item loading starting from longest to shortest version.
   */
  langTextKeys?: Array<string>;
  progress?: ItemLoadingProgress;
}

export type ItemLoadingProgress = ItemLoadingFakeProgress;

export interface ItemLoadingFakeProgress {
  type: ItemLoadingProgressType.FAKE;
  /**
   * Approximate time in Seconds that the fake loading should take. The loader will hit 85% at this time and slow down drastically after that
   */
  expectedTotalTime: number;
}

export enum ItemLoadingProgressType {
  FAKE = 'fake',
}
export interface ItemConfig {
  x?: number;
  y?: number;
  alpha?: number;
  width?: number;
  height?: number;
  rotation?: number;
  visible?: boolean;
  erasable?: boolean;
  glow?: number;
  dropShadow?: number;
  dropShadowColor?: RGB;
  dropShadowAlpha?: number;
  dropShadowAngle?: number;
  solidBorderType?: number;
  solidBorderColor?: RGB;
  solidBorderThickness?: number;
  scaleX?: number;
  scaleY?: number;
  flipX?: boolean;
  flipY?: boolean;
  clickableLink?: string;
  lockMovement?: boolean;
  fillType?: number;
  gradientFillColor1?: RGB;
  gradientFillColor2?: RGB;
  patternFill?: number;
  version?: number;
  isNew?: boolean;
  setActiveOnLoad?: boolean;
}

export interface BaseItemObject {
  uid: string;
  gitype: ITEM_TYPE;
  idOriginalOwner?: number;
  x: number;
  y: number;
  alpha: number;
  width: number;
  height: number;
  rotation: number;
  visible: boolean;
  erasable: boolean;
  clickableLink: string;
  setActiveOnLoad: boolean;
  border: ItemBorderObject;
  aura: ItemAuraObject;
  isNew: boolean;
  scaleX: number;
  scaleY: number;
  flipX: boolean;
  flipY: boolean;
  lockMovement: boolean;
  version: number;
}

export interface ItemKeysFromFabricObject {
  x: Item['x'];
  y: Item['y'];
  width: Item['width'];
  height: Item['height'];
  rotation: Item['rotation'];
  scaleX: Item['scaleX'];
  scaleY: Item['scaleY'];
  flipX: Item['flipX'];
  flipY: Item['flipY'];
}

export const SHADOW_REFERENCE_DIMENSION = 500;
